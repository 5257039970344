<template>
<div>



    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>{{ $t('Show')}}</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>{{ $t('entries')}}</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        <b-button variant="primary" :to="{ name: 'register-group' }">
                            <span class="text-nowrap">{{ $t('Add Group')}}</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>

        </div>

        <b-table ref="refGroupListTable" class="position-relative" :items="fetchAllGroup" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="emptyText" :sort-desc.sync="isSortDirDesc">

            <!-- Column: Name -->
            <template #head(name)>
                {{ $t('Name')}}
            </template>
            <template #cell(name)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.name }}
                </div>
            </template>

            <!-- Column: Qtd Assets -->
            <template #head(assetsCount)>
                {{ $t('Amount of Assets')}}
            </template>
            <template #cell(assetsCount)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.assetsCount }}
                </div>
            </template>

            <!-- Column: Actions -->
            <template #head(actions)>
                {{ $t(selectedAssetId ? 'Select group' : 'Actions') }}
            </template>
            <template #cell(actions)="data">
                <b-button variant="primary" v-if="selectedAssetId" @click="bindAssetToGroup(selectedAssetId, data.item.id)">
                  <feather-icon icon="CheckSquareIcon" />
                </b-button>
                <b-dropdown v-else variant="link" no-caret :right="$store.state.appConfig.isRTL">

                    <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item :to="{ name: 'register-group', params: { id: data.item.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">{{ $t('Edit')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="openModalBind(data.item.id)">
                        <feather-icon icon="PlusCircleIcon" />
                        <span class="align-middle ml-50">{{ $t('Add asset in group')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="modalDelete(data.item.id)">
                        <feather-icon icon="Trash2Icon" />
                        <span class="align-middle ml-50">{{ $t('Delete')}}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted"> {{ $t('Showing')}} {{ dataMeta.from }} {{ $t('to')}} {{ dataMeta.to }} {{ $t('of')}} {{ dataMeta.of }} {{ $t('entries')}}</span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                    <b-pagination v-model="currentPage" :total-rows="totalGroup" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>
        </div>
    </b-card>
    <b-modal v-model="showModal" no-close-on-backdrop id="modal-cadastro" size="md" title="Cadastro" title-class="font-18" hide-footer @hide="limparFormCadastro">
    <div class="row ">
        <div class="col-md-12">
            <p class="sub-header font-weight-bold">{{$t('Choose the asset to add to the group')}}</p>
        </div>
    </div>
    <form >
        <div class="row">
            <b-col cols="12" md="12">
                <b-form-group :label="$t('Asset')" label-for="asset">
                    <vue-autosuggest v-model="asset.name" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                        <template slot-scope="{suggestion}">
                            <span class="my-suggestion-item">{{ suggestion.item.plate ?  suggestion.item.plate : suggestion.item.chassisNumber}}</span>
                        </template>
                    </vue-autosuggest>
                </b-form-group>
            </b-col>
        </div>
        <div class="form-group text-right mt-1">
            <button class="btn btn-primary" type="button" @click="bindGroup()">{{$t('Add')}}</button>
        </div>

    </form>
</b-modal>
</div>


</template>

<script>


import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    BModal
} from 'bootstrap-vue'

import { VueAutosuggest } from 'vue-autosuggest'

import store from '@/store'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'

import managementStoreModule from '../managementStoreModule'
import useGroupList from './useGroupList'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
    props: ["selectedAssetId"],
    components: {
        //UsersListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BCardHeader,
        BCardBody,
        BModal,
        vSelect,
        flatPickr,
        VueAutosuggest


    },
    data() {
        return {
            //Datas autocomplete asset


            emptyText: this.$t('No matching records found'),
            showModal: false,
            asset:{
                name:'',
                id:''
            },

            datasuggest: [],
            filteredOptions: [],
            inputProps: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Search for your asset ...",
            },
            limit: 5,
            groupId:''


        }
    },
    methods: {

        selectHandler(option) {
            //this.selected = option.item
            //this.searchQuery = option.item.plate ? option.item.plate : option.item.chassisNumber
            console.log('opt: ', option)
            this.asset.id = option.item.id
            this.asset.name = option.item.plate ? option.item.plate : option.item.chassisNumber
            this.filteredOptions = []


        },

        onInputChange(text) {
            if (text === '' || text === undefined || text.length < 3) {

                return
            }

            store.dispatch('app-management/fetchAssets', {
                    q: text,
                    excludeGrouped: true
                })
                .then(response => {

                    this.filteredOptions = [{
                        data: response.data.data,
                    }]

                })
                .catch(error => {

                    console.log(error)

                })
        },

        openModalBind(id){
            console.log('abriu')
            this.showModal = true
            this.groupId = id
        },

        limparFormCadastro(){
            this.asset = {
                name:'',
                id:''
            }

            this.groupId = ''
        },

        bindGroup() {
          this.bindAssetToGroup(this.asset.id, this.groupId)
        },
        bindAssetToGroup(assetId, groupId) {
          this.showModal = false

          store.dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/bindAssetToAssetGroup`, {
            assetId: assetId,
            assetGroupId: groupId
          })
              .then(_ => {
                this.$swal('Success', this.$t('Asset added'), 'success')
                this.refetchData()
                this.$emit("bind")
              })
              .catch(error => {
                this.$swal('Error', '', 'error')
              })
        },

        modalDelete(myId) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.value) {
                    console.log('ID', myId)
                    this.deletAassetGroup(myId)

                } else {}
            })
        },

        deletAassetGroup(myId) {
            store.dispatch('app-management/deletAassetGroup', {
                    id: myId
                })
                .then(response => {
                    this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
                    this.refetchData()

                })
                .catch(error => {
                    this.$swal('Error', 'Your fuelling is still intact', 'error')
                    console.log(error);
                })
        }
    },
    setup() {
        const MANAGEMENT_APP_STORE_MODULE_NAME = 'app-management'

        // Register module
        if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.registerModule(MANAGEMENT_APP_STORE_MODULE_NAME, managementStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME)
        })

        const {
            fetchAllGroup,
            tableColumns,
            perPage,
            currentPage,
            totalGroup,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refGroupListTable,
            refetchData,


        } = useGroupList()

        return {
            fetchAllGroup,
            tableColumns,
            perPage,
            currentPage,
            totalGroup,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refGroupListTable,
            refetchData,
            MANAGEMENT_APP_STORE_MODULE_NAME
        }
    },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.per-page-selector {
    width: 90px;
}
</style>
